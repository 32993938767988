import React from "react"
import { Link } from "gatsby"


class ContactForm extends React.Component {

  constructor() {
    super()
    this.state = {}
  }


  getNameValue = event => {
    this.setState({
      name: event.target.value
    })
  }

  getEmailValue = event => {
    this.setState({
      email: event.target.value
    });
  }

  getMessageValue = event => {
    this.setState({
      message: event.target.value
    });
  }

  getPhoneValue = event => {
    this.setState({
      phone: event.target.value
    });
  }

  getCompanyValue = event => {
    this.setState({
      company: event.target.value
    });
  }

  validateEmail = () => {
    const regexEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (regexEmail.test(this.state.email)) {
      document.querySelector(".email").style.color = "white";
      document.querySelector(".email-input").style.borderColor = "white";

      return true;
    }

    document.querySelector(".email").style.color = "#FF0000";
    document.querySelector(".email-input").style.borderColor = "#FF0000";

    return false;
  }

  validateName = () => {
    const regexName = /[a-zA-Z][^#&<>\"~;$^%{}?]{3,}$/;

    if (regexName.test(this.state.name)) {
      document.querySelector(".name").style.color = "#fff";
      document.querySelector(".name-input").style.borderColor = "#fff";

      return true;
    }

    document.querySelector(".name").style.color = "#FF0000";
    document.querySelector(".name-input").style.borderColor = "#FF0000";

    return false;
  }

  send = () => {
    if(this.validateEmail() && this.validateName()){

      document.getElementById("submit").innerText = "Submitting...";

      const names = this.state.name.split(" ");

      const data = {
        "fields": [
          {
            "name": "email",
            "value": this.state.email
          },
          {
            "name": "firstname",
            "value": names[0]
          },
          {
            "name": "lastname",
            "value": names[1]
          },
          {
            "name": "company",
            "value": this.state.company
          },
          {
            "name": "phone",
            "value": this.state.phone
          },
          {
            "name": "message",
            "value": this.state.message
          }
        ],
        "context": {
          "hutk": this.getCookie("hubspotutk"), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
          "pageUri": window.location.href,
          "pageName": document.title
        },
        "legalConsentOptions": {
          "consent": { // Include this object when GDPR options are enabled
            "consentToProcess": true,
            "text": "I agree to allow Example Company to store and process my personal data.",
            "communications": [
              {
                "value": true,
                "subscriptionTypeId": 999,
                "text": "I agree to receive marketing communications from Example Company."
              }
            ]
          }
        },
        "skipValidation": true
      }

      const httpRequest = new XMLHttpRequest();

      if (!httpRequest) {
        console.log('Giving up :( Cannot create an XMLHTTP instance');
        return false;
      }

      httpRequest.onreadystatechange = function() {
        if (httpRequest.readyState === XMLHttpRequest.DONE) {
          if (httpRequest.status === 200) {
            console.log(httpRequest.responseText);
            document.getElementById("submit").innerText = "Thanks for filling out our form!"
          } else {
            console.log('There was a problem with the request.');
          }
        }
      }

      httpRequest.open('POST', 'https://api.hsforms.com/submissions/v3/integration/submit/4857289/656bf71a-f7eb-4a66-ad09-2fbe0c89785b');
      httpRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      httpRequest.send(JSON.stringify(data));
    }
  }

  getCookie = (name) => {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  resizeTextArea() {
    document.getElementById("textarea").classList.add("expand-textarea");
  }

  render() {
    return (
      <div id="contactForm">

        <div className="leftPart">
          <div className="leftContactContainer">     
            <h3>We’d <span>love to hear</span> from you</h3>
            <a href="mailto:office@icon-worldwide.com">office@icon-worldwide.com</a>
            <a href="tel:+41435442764">+41 43 544 27 64</a>
          </div>
        </div>

        <div className="rightPart">
          <form id="the-form">
            <p className="name">Name<span>*</span></p>
            <input type="text" name="name" className="name-input" onChange={this.getNameValue} />

            <p className="email">Email<span>*</span></p>
            <input type="text" name="email" className="email-input" onChange={this.getEmailValue} />

            <p>Company Name</p>
            <input type="text" name="company_name" onChange={this.getCompanyValue} />

            <p>Phone Number</p>
            <input type="text" name="phone_number" onChange={this.getPhoneValue} />

            <p>Message</p>
            <textarea id="textarea" name="message" onFocus={this.resizeTextArea} onChange={this.getMessageValue}  />

            <div className="btnlink">
              <div id="submit" onClick={this.send}>Submit</div>
            </div>
          </form>
        </div>

      </div>
    )
  }
}

export default ContactForm
