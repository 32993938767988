import React from "react"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import CookieBar from "../components/cookieBar"
import ServiceMenu from "../components/services/servicesMenuHeader"
import ContactForm from "../components/contactForm"

export default () =>(
    <div id="contact-page">
        <SEO title={'Contact Us'} canonical={'https://icon-worldwide.com/contact-us'} />
        <CookieBar/>
        <ServiceMenu/>
        <div>
            <ContactForm/>
        </div>
        <Footer noScrollbar="true"/>
    </div>
)
